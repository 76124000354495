var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"d-flex flex-wrap justify-start",attrs:{"xl":"8","lg":"10","md":"12","cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.workspaces),function(workspace,index){return _c('v-col',{key:index,staticClass:"pb-3",class:index % 2 === 0 && _vm.$vuetify.breakpoint.mdAndUp ? 'pr-6' : '',attrs:{"md":"6","cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":"","max-width":"400","max-height":"125","min-width":"400","min-height":"125","elevation":"1"},on:{"click":function($event){return _vm.openFlows(workspace.id)}}},[_c('v-card-title',[_c('Avatar',{attrs:{"size":25,"getUser":{ email: workspace.owner.email || '' },"fontSizeClass":'caption'}}),_c('span',{staticClass:"body-1 ml-2"},[_vm._v(_vm._s(_vm.truncated(workspace.name)))])],1),_c('v-card-actions',{staticClass:"pb-0"},[_c('v-list-item',{staticClass:"grow"},[_c('v-row',{attrs:{"justify":"end","no-gutters":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"mr-1 mt-2"},[_vm._v(" mdi-account-group-outline ")]),_c('span',{staticClass:"subheading mr-4 pt-2"},[_vm._v(_vm._s(workspace.members.length))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('number_of_members')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"mr-1 mt-2"},[_vm._v(" mdi-sitemap ")]),_c('span',{staticClass:"subheading mr-4 pt-2"},[_vm._v(_vm._s(workspace.flows.length))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('number_of_flows')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","elevation":"4"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.replace(("/workspacesetting/" + (workspace.id)))}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cog")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('workspace_setting')))])])],1)],1)],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }