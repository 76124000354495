
























import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import WorkspaceList from '@/components/workspaces/WorkspaceListContainer.vue';
import Loading from '@/components/shared/Loading.vue';
import { FETCH_USER_WORKSPACES, GET_LOADING, GET_USER } from '@/store/types';
import FailedMessage from '@/components/shared/FailedMessage.vue';

const workspace: string = 'workspace';
const user: string = 'user';

@Component({
  components: {
    WorkspaceList,
    Loading,
    FailedMessage,
  },
})
export default class Workspaces extends Vue {
  // Getters
  @Getter(GET_USER, { namespace: user }) getUser: any;

  @Getter(GET_LOADING, { namespace: workspace }) getLoading: any;

  // Action
  @Action(FETCH_USER_WORKSPACES, { namespace: workspace }) fetchUserWorkspaces: any;

  // Vue Life Cycle Hooks

  // We fetch the workspaces from Navidation bar, since for each loading we need to
  // get the workspaces
}
